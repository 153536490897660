<template>
  <main class="bg-light">
    <div>
      <keep-alive>
        <top-nav/>
      </keep-alive>
    </div>
    <div>
      <router-view/>
    </div>
    <b-container class="mt-4">
      <b-row>
        <b-col v-if="pending">
        </b-col>
        <b-col v-else>
          <b-table-simple small responsive="" bordered class="bg-white">
            <b-thead>
              <b-tr>
                <b-th>SEQ</b-th>
                <b-th>TYPE</b-th>
                <b-th>TITLE</b-th>
                <b-th>CONTENT</b-th>
                <b-th>노출 여부</b-th>
                <b-th>작성자</b-th>
                <b-th>작성일시</b-th>
                <b-th>우선순위</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="news in newsLetter"
                :key="`news-letter-${news._id}`"
                :class="selectedId === news._id ? 'active' : ''"
              >
                <b-td>
                  <router-link
                    :to="`/news-letter/${news._id}`"
                  >
                    {{ news._id }}
                  </router-link>
                </b-td>
                <b-td>
                  {{ news.kind }}
                </b-td>
                <b-td>
                  <div class="d-block" style="max-width: 200px;">
                    <p class="text-truncate my-1">
                      {{ news.title }}
                    </p>
                  </div>
                </b-td>
                <b-td>
                  <div class="d-block" style="max-width: 200px;">
                    <p class="text-truncate my-1">
                      {{ news.content }}
                    </p>
                  </div>
                </b-td>
                <b-td>
                  {{news.use ? '노출' : '숨김'}}
                </b-td>
                <b-td>
                  {{ news.regUserName }}
                </b-td>
                <b-td>
                  {{ new Date(news.regDate).toLocaleString() }}
                </b-td>
                <b-td>
                  {{news.priority ? '사용중' : ''}}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="d-flex justify-content-end mb-2">
            <b-button
              to="/news-letter/new"
              variant="danger"
              size="sm"
              squared
            >
              <font-awesome-icon icon="plus"/> 등록
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination-nav
              v-if="newsLetter.length"
              :link-gen="linkGen"
              v-model="page"
              :number-of-pages="numberOfPages"
              use-router
              aria-controls="my-table"
              first-number
              last-number
              first-text="«"
              prev-text="‹"
              next-text="›"
              last-text="»"
              align="right"
              size="sm"
          />
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
export default {
  name: "news-letter",
  created () {
    const {_id} = this.$route.params
    if (_id) this.selectedId = _id
    const {page, type, search} = this.$route.query
    if (page && !isNaN(+page)) this.page = +page
    if (type) this.filterData.type = type
    if (search) this.filterData.search = search
    this.load(this.page)
  },
  data: () => ({
    pending: false,
    page: 1,
    total: 0,
    perPage: 0,
    newsLetter: [],
    selectedId: null,
    error: null,
    filterData: {
      type: null,
      search: null
    },
    numberOfPages: 0,
  }),
  methods: {
    async load (pageNo) {
      this.pending = true
      try {
        this.page = pageNo ? isNaN(+pageNo) ? 1 : +pageNo : 1
        const params = {
          page: this.page,
          type: this.filterData.type,
          search: this.filterData.search,
          adminYN: 'Y'
        }
        const {data} = await this.axios({
          url: `/support/news-letter`,
          params
        })
        const {result, newsLetter, total, perPage, page, selectedData, numberOfPages} = data
        if (result) {
          this.newsLetter = newsLetter
          this.total = total
          this.page = page
          this.perPage = perPage
          this.numberOfPages = numberOfPages
        }
      } catch (e) {
      }
      this.pending = false
    },
    linkGen (page) {
      // return `?page=${page}`
      return `${this.$route.path}?page=${page}&${Object.entries(this.filterData).map(v => v[1] ? `${v[0]}=${v[1]}` : `${v[0]}`).join('&')}`
    },
    search () {
      const path = `${this.$route.path}?page=1&${Object.entries(this.filterData).map(v => v[1] ? `${v[0]}=${v[1]}` : `${v[0]}`).join('&')}`
      this.$router.push({path})
    },
    goDetail (_id) {
      return `${this.$route.path}/${_id}?page=${this.page}&${Object.entries(this.filterData).map(v => v[1] ? `${v[0]}=${v[1]}` : `${v[0]}`).join('&')}`
    }
  }
}
</script>

<style scoped>
  main {
    min-height: 100vh;
  }
  th, td {
    font-size: 0.8rem;
    vertical-align: middle;
  }
  tr.active {
    color: red;
    font-weight: bold;
    background-color: #F5F5F5;
  }
</style>
